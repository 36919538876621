import React from 'react';
import '../style/nav.css';

function Nav() {
  return (

    <nav>
      <h1>FOOTWEAR FOCUS</h1>
      <div></div>
    </nav>
    
  );
}

export default Nav;
