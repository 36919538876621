import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Nav from './components/Nav';
import Home from './components/Home';
import Footer from './components/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {

  const TRACKING_ID = "G-WYEYWJMDT5"; 
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    AOS.init({
      duration: 400, // Animation duration
      once: true, // Whether animation should happen only once
      // other options can go here
    });
  }, []);

  return (
    <Router>
      <div className="App">     
      <Nav />  
      <Routes>
        <Route path="/" exact element={<Home/>} />
      </Routes>
      <Footer />       
    </div>
    </Router>
  );
}

export default App;
