import React from 'react';
import '../style/footer.css';

const Footer = React.forwardRef((props, ref) => (
  <footer ref={ref}>
    <span>© 2024 Bare Footwear. All Rights Reserved. Privacy Policy Terms of Service</span>
    <br />
    <br />
    <span>THIS IS AN ADVERTISEMENT AND NOT AN ACTUAL NEWS ARTICLE, BLOG, OR CONSUMER PROTECTION UPDATE</span>
  </footer>
));

export default Footer;
