import React, { useState, useEffect, useRef } from "react";
import ProductAd from "./ProductAd";
import Sticky from "react-sticky-el";
import "../style/home.css";
import x from "../assets/x.svg";
import image from "../assets/1.webp";
import image2 from "../assets/2.webp";
import image3 from "../assets/3.webp";
import threeshoes from "../assets/pictures/3shoes.jpg";
import Animation1 from "../assets/pictures/Animation1.gif";
import Animation2 from "../assets/pictures/Animation2.gif";
import beige from "../assets/pictures/beige.jpg";
import foot from "../assets/resized/feet.webp";
import lakeside from "../assets/resized/lakeside.webp";
import onrock from "../assets/resized/onrock.webp";
import shoe from "../assets/resized/standing.webp";
import sole from "../assets/pictures/sole.jpg";
import togheter from "../assets/pictures/togheter.jpg";
import doc from "../assets/resized/doctors.webp";
import onBench from '../assets/resized/onbench.webp';
import ReactGA from 'react-ga4';


function Home() {

  const greenBtnRef = useRef(null);
  const popupRef = useRef(null);

  // Register google analytics button click
  const handleClick = (button) => {
    ReactGA.event({
      category: 'Button',
      action: 'Custom Button Click',
      label: button  
    });
  };  

  const closePopup = () => {

    // Register google analytics button click
    ReactGA.event({
      category: 'Button',
      action: 'Custom Button Click',
      label: 'Close Mobile Add'  
    });

    // Close popup and show green button
    greenBtnRef.current.classList.toggle('hide');
    popupRef.current.classList.toggle('hide');
  }

  // Register google analytics page view
  useEffect(() => {
  ReactGA.send({
    hitType: 'PageView',
    route: '/',
    page: "Home" 
  });
  }, [])

  // Open popup after 7 seconds
  useEffect(() => {

    // Only open on mobile devices
    if (window.matchMedia('(max-width: 748px)').matches) {

    const timer = setTimeout(() => {

    // Open popup and hide green button
    greenBtnRef.current.classList.toggle('hide');
    popupRef.current.classList.toggle('hide');
      
    }, 7000);
    return () => clearTimeout(timer);

  }
  }, []);


  return (
    <main id="pageWrapper">
      <article>
        <div id="contentRow">
          <div id="contentColumn">            
            <div id="popupWrapper" className="hide" ref={popupRef}>            
                <button className="closeButton" onClick={() => closePopup()}><img src={x} alt="X" ></img></button>
                <ProductAd adLocation="Popup Button"/>
            </div>
            <div id="titleWrapper">
              <h1>
                Footwear For Back Pain Relief
                {/* <span className="emojiWrapper">
              <span className="emoji">&#128095;</span>
            </span> */}
              </h1>
              <span id="author">
                By Bart van den Hoven | July 12th, 2024 | 9:20 am EST
              </span>
            </div>

            <div id="contentWrapper">
              <div className="contentIntro">
                <img src={threeshoes} alt="Bare Footwear" />

                <p>
                  <span className="strongText">
                    Switch to these shoes for effective back pain relief and
                    improved overall well-being.&nbsp;
                  </span>
                </p>
              </div>

              <div data-aos="fade-up" className="contentSection">
                <img src={shoe} alt="Bare Footwear" />

                <div className="contentText">
                  <h2>1. Natural Alignment</h2>

                  <p>
                    <span className="regularText">
                      Footwear designed to promote{" "}
                      <strong>natural alignment</strong> allows for unrestricted
                      foot movement, aiding in maintaining proper posture and
                      spinal alignment, thereby{" "}
                      <strong>reducing back strain.</strong>
                    </span>
                  </p>
                </div>
              </div>

              <div id="stickyWrapper">

                <div data-aos="fade-up" className="contentSection">

                  <img src={foot} alt="Bare Footwear" />
              
                  <div className="contentText">
                    <h2>2. Wide Toe Box</h2>

                    <p>
                      <span className="regularText">
                        Shoes with a <strong>spacious toe box</strong> enable
                        natural toe splay, improving balance and stability,
                        which can <strong>alleviate back pain</strong>
                        associated with postural issues.
                      </span>

                      <br></br>
                      <br></br>

                      <span className="regularText">
                        The <strong>Hike Footwear Shoes</strong> has a{" "}
                        <strong>
                          strong rubber slip-resistant traction grip
                        </strong>{" "}
                        that will keep you stable on your feet regardless of any
                        liquid on the floor.
                      </span>
                    </p>
                  </div>
                </div>

                <div data-aos="fade-up" className="contentSection">
                  <img src={doc} alt="Bare Footwear" />

                  <div className="contentText">
                    <h2>3. Endorsement by Podiatrists</h2>

                    <p>
                      <span className="regularText">
                      Recommended by foot health experts, these shoes are crafted to <strong>provide support 
                      and pain relief</strong>, specifically targeting chronic back pain through optimal alignment.
                      </span>
                    </p>
                  </div>
                </div>

                <div data-aos="fade-up" className="contentSection">
                  <img src={sole} alt="Bare Footwear" />

                  <div className="contentText">
                    <h2>4. Thin Sole Design</h2>

                    <p>
                      <span className="regularText">
                      Featuring a thin sole for <strong>enhanced ground feel</strong>, these shoes contribute to 
                      better posture and minimize spinal impact, potentially <strong>preventing and easing back discomfort.</strong>
                      </span>
                    </p>
                  </div>
                </div>

                <div data-aos="fade-up" className="contentSection">
                  <img src={onrock} alt="Bare Footwear" />

                  <div className="contentText">
                    <h2>5. Flexibility</h2>

                    <p>
                      <span className="regularText">
                      The flexibility inherent in these shoes <strong>promotes natural foot movement</strong>, 
                      enhancing overall body alignment and <strong>reducing stress on the back.</strong>
                      </span>
                    </p>
                  </div>
                </div>

                <div data-aos="fade-up" className="contentSection">
                  <img src={onBench} alt="Bare Footwear" />

                  <div className="contentText">
                    <h2>6. Lightweight Construction</h2>

                    <p>
                      <span className="regularText">
                      Designed for lightweight comfort, these shoes <strong>reduce foot and leg fatigue</strong>, 
                      supporting spinal health by minimizing strain during daily activities.
                      </span>
                    </p>
                  </div>
                </div>

                <div data-aos="fade-up" className="contentSection">
                  <img src={lakeside} alt="Bare Footwear" />

                  <div className="contentText">
                    <h2>7. Comfort and Longevity</h2>

                    <p>
                      <span className="regularText">
                      Engineered for all-day comfort, these shoes <strong>mitigate fatigue and back stress</strong>, 
                      crucial for maintaining spinal health and <strong>preventing back pain.</strong>
                      </span>
                    </p>
                  </div>
                </div>

                <div data-aos="fade-up" className="finalSection">
                  <h2>
                    How To Get The Best Results For Your Feet At The Best Price
                    Possible! (And FREE Shipping!)
                  </h2>

                  <p>
                    <span>
                    <strong>Special Offer:</strong> For a limited time, enjoy over <strong>20% off</strong> exclusively on the <strong>Bare Footwear</strong> website. Plus, get <strong>FREE shipping!</strong>
                    </span>

                    <br></br>
                    <br></br>

                    <span>
                      <strong>Step 1:</strong> Order your Bare Footwear product today to secure the 20% discount (while supplies last).
                    </span>

                    <br></br>
                    <br></br>

                    <span>
                      <strong>Step 2:</strong> Step 2: Allow yourself a couple of days to adjust to the comfort and support of Bare Footwear.
                    </span>

                    <br></br>
                    <br></br>

                    <span>
                      <strong>Step 3:</strong> Experience all-day comfort and support with Barefoot Shoes 
                      from Bare Footwear. Enjoy every step as discomfort disappears.
                    </span>

                    <br></br>
                    <br></br>

                    <span>
                      <strong>Gift Idea:</strong> Treat a barefoot enthusiast to the gift of comfort and support for their all-day activities.
                    </span>
                  </p>
                </div>

                <Sticky
                  mode={"bottom"}
                  boundaryElement="#stickyWrapper"
                  hideOnBoundaryHit={true}
                  stickyClassName={"sticky"}
                >
                  <a href="https://barefootwear.com/products/bareshoes-classic-knit-white" target="_blank" rel="noreferrer" ref={greenBtnRef}>
                  <button className="btn-green" onClick={() => handleClick("Green Page Button")}>
                    CLICK HERE TO CHECK THE PRICE AND AVAILABILITY 👉
                  </button>
                  </a>
                  <div className="btn-spacer"></div>
                </Sticky>
              </div>
            </div>
          </div>

          <div data-aos="fade-left" id="articleColumn">
            <ProductAd adLocation="Sidebanner Button"/>
          </div>
        </div>
      </article>
    </main>
  );
}

export default Home;
